.deploy-button-container {
  flex: 1;
  display: flex; 
  padding: 1%;
  margin: 5%;
}

.instructions-and-actions-sidebar {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.instructions-menu-header {
  flex: 2;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(20, 20, 20);
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 5px 5px 5px black;
  padding: 1%;
  margin: 1%;
  overflow-y: auto;
  height: 100%;
  text-align: center;
}

.instructions-menu-list-container {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: gray;
  border: 5px solid black;
  flex: 19;
  height: 100%;
  margin: 1%;
  overflow-y: auto;
  padding: 1%;
}

.instructions-menu-list-item {
  border: 1px solid black;
  margin: 1px;
}

.instructions-menu-list-item-button {
  color: white;
  font-size: 0.75em !important;
  text-shadow: 1px 1px 1px gray;
}