.code-container {
  align-items: center;
  background-color: #282a36;
  border: 2px solid black;
  border-radius: 10px;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 95%;
  width: 10%;
  font-size: 0.8em;
  overflow: auto;
}

.code-container-div {
  display: flex;
  flex: 1;
  height: 100%;
  margin: 20px;
  width: 10%;
}