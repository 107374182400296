.contract-container-container {
  align-items: center;
  background-color: rgb(39 207 230);
  display: flex;
  flex-direction: row;
  flex: 20;
  justify-content: center;
  max-height: 100%;
  height: 100px;
}

.contract-container-box {
  align-items: center;
  background-color: white;
  border: 2px solid gray;
  border-radius: 10px;
  color: black;
  flex: 2;
  height: 95%;
  width: 100%;
  margin-top: 3%;
  margin-left: 1%;
  margin-bottom: 5%;
}
