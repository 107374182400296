.instruction-node-assign {
  background-color: white;
  margin: 2px;
  padding: 2px;
  text-align: left;
  font-size: 0.5em;
  border: black 1px dashed;
}

.instruction-node-content {
  align-items: center;
  align-content: center;
  font-size: 0.5em;
  text-align: center;
  text-shadow: 1px 1px 1px gray;
  width: 200px;
}

.instruction-node-input {
  background-color: white;
  margin: 2px;
  padding: 2px;
  text-align: left;
  font-size: 0.5em;
  border: black 1px dotted;
}


.instruction-node-input-to-instruction {
  background-color: white;
  /* margin: 2px; */
  padding: 2px;
  text-align: left;
  font-size: 0.5em;
  border: black 1px solid;
  text-align: left;
  overflow-x: auto;
  width: 100%;

}